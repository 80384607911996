import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { hydrate } from 'react-dom';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import Loadable from 'react-loadable';

import Root from './Root';
import * as serviceWorker from './serviceWorker';

import './scss/root.scss';

// setup moment locale
moment.updateLocale('bg', {
  months: [
    'Януари',
    'Февруари',
    'Март',
    'Април',
    'Май',
    'Юни',
    'Юли',
    'Август',
    'Септември',
    'Октомври',
    'Ноември',
    'Декември',
  ],
});

Sentry.init({
  environment: process.env.RAZZLE_ENVIRONMENT,
  dsn: process.env.RAZZLE_SENTRY_DSN,
});

function render(Root) {
  Loadable.preloadReady().then(() => {
    hydrate(
      <BrowserRouter>
        <Root />
      </BrowserRouter>,
      root
    );
  });
}

const root = document.getElementById('root');

window.main = () => {
  render(Root);
};

if (module.hot) {
  module.hot.accept('./Root', () => {
    const NewRoot = require('./Root').default;
    render(NewRoot);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
