import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import CookieConsent from 'react-cookie-consent';

import SentryErrorBoundary from './common/SentryErrorBoundary';
import Loading from './common/Loading';
import requiresFeatureFlag from './common/hoc/requiresFeatureFlag';

// App bundles
const Landing = Loadable.Map({
  loader: {
    App: () => import('./apps/landing/App'),
    styles: () => import('./scss/main.landing.scss')
  },
  loading: Loading,
  render(loaded, props) {
    const App = loaded.App.default;
    return <App {...props} />;
  }
})

const AthleteApp = requiresFeatureFlag('athleteApp')(
  Loadable.Map({
    loader: {
      App: () => import('./apps/athlete/App'),
      styles: () => import('./scss/main.athlete.scss')
    },
    loading: Loading,
    render(loaded, props) {
      const App = loaded.App.default;
      return <App {...props} />;
    }
  })
);

const AdminApp = Loadable.Map({
  loader: {
    App: () => import('./apps/admin/App'),
    styles: () => import('./scss/main.admin.scss')
  },
  loading: Loading,
  render(loaded, props) {
    const App = loaded.App.default;
    return <App {...props} />;
  }
});

class Root extends Component {
  render() {
    return (
      <SentryErrorBoundary>
        <div className="c-root">
          <Switch>
            <Route
              path="/athlete"
              component={(routeProps) => (
                <AthleteApp {...this.props.cookie} {...routeProps} />
              )}
            />
            <Route
              path="/admin"
              component={(routeProps) => (
                <AdminApp {...this.props.cookie} {...routeProps} />
              )}
            />
            <Route path="/" component={Landing} />
          </Switch>
          <CookieConsent
            containerClasses="c-cookie-consent__container"
            buttonClasses="c-cookie-consent__button"
            disableButtonStyles={true}
            buttonText="Разбирам"
          >
            Нашият сайт използва "бисквитки", за да работи оптимално за Вас.
          </CookieConsent>
        </div>
      </SentryErrorBoundary>
    );
  }
}

export default Root;
