import React, { Component } from 'react';

const requiresFeatureFlag = (featureFlagName) => (ProtectedComponent) => {
  class FeatureFlagGateway extends Component {
    render() {
      return JSON.parse(process.env.RAZZLE_FEATURE_FLAGS).includes(
        featureFlagName
      ) ? (
        <ProtectedComponent {...this.props} />
      ) : null;
    }
  }

  return FeatureFlagGateway;
};

export default requiresFeatureFlag;
