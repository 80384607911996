import React, { useState, useEffect } from 'react';

const Loader = ({ className, delay = 300 }) => {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowLoader(true), delay);
    return () => clearTimeout(timer);
  });
  return (
    showLoader && <div className={`c-loading ${className ? className : ''}`} />
  );
};

export default Loader;
