/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

class SentryErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.error) {
      // TODO: render fallback UI
      return (
        <div>
          <h2>Something went wrong</h2>
          <a
            onClick={() =>
              Sentry.showReportDialog({ eventId: this.state.eventId })
            }
          >
            Report feedback
          </a>
        </div>
      );
    } else {
      // when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default SentryErrorBoundary;
